import ProjectTypesService from '../services/project-types';

const projectTypes = {
    namespaced: true,
    state: {
        types: [],
    },
    mutations: {
        insertData(state, payload) {
            state.types = payload;
        },
    },
    actions: {
        async getAllTypes({ commit }) {
            const data = await ProjectTypesService.findAll();
            commit('insertData', data);
        },
    },
    getters: {
        getTypes(state) {
            return state.types;
        },
    },
};

export default projectTypes;
