import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import loader from './loader.module';
import notifier from './notifier.module';
import projectTypes from './project-types.module';
import projectCompany from './project-company.module';
import plans from './plans.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        loader,
        notifier,
        projectTypes,
        projectCompany,
        plans,
    },
});
