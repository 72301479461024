import Main from '@/layouts/Main';

export default {
    path: '/',
    redirect: { name: 'main.home' },
    component: Main,
    children: [
        {
            path: 'home',
            name: 'main.home',
            component: () => import('../views/main/Home.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Início',
                icon: 'mdi-home',
                hide: false,
            },
        },
        {
            path: 'projetos',
            name: 'main.projects',
            component: () => import('../views/main/Projects/List.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Projetos',
                icon: 'mdi-bullseye-arrow',
                hide: false,
            },
        },
        {
            path: 'projetos-em-destaque',
            name: 'main.featured-projects',
            component: () => import('../views/main/Projects/Featured.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Projetos em Destaque',
                icon: 'mdi-light-flood-up',
                hide: false,
            },
        },
        {
            path: 'usuarios',
            name: 'main.users',
            component: () => import('../views/main/Users/List.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Usuários',
                icon: 'mdi-account-group',
                hide: false,
            },
        },
        {
            path: 'usuario-parceiro',
            name: 'main.user-partner',
            component: () => import('../views/main/PartnerUser/List.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Usuário Parceiro',
                icon: 'mdi-human-greeting-proximity',
                hide: false,
            },
        },
        {
            path: 'depoimentos',
            name: 'main.statements',
            component: () => import('../views/main/Statements/List.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Depoimentos',
                icon: 'mdi-comment-account',
                hide: false,
            },
        },
        {
            path: 'empresas-amigas',
            name: 'main.friendCompany',
            component: () => import('../views/main/FriendCompany/List.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Empresas Amigas',
                icon: 'mdi-handshake',
                hide: false,
            },
        },
        {
            path: 'relatorio-bd-vs-pagarme',
            name: 'main.report',
            component: () => import('../views/main/DonationReport/Form.vue'),
            meta: { requireLogin: true },
            props: {
                title: 'Relatório de Doações',
                icon: 'mdi-database-eye-outline',
                hide: false,
            },
        },
    ],
};
