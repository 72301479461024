var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.visible,"width":"700","persistent":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","dense":"","color":"#B91256"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Preencha o formulário abaixo para solicitar o estorno")]),_c('v-spacer')],1),_c('v-form',{ref:"formRefundBoleto"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"10","md":"10","sm":"10"}},[_c('v-card',{staticClass:"pa-5",attrs:{"color":"#EEF6F6","elevation":"0"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Código do Banco *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.minThreeNumbers,
                                            ],"hint":"O código que identifica o banco. Exemplo: 001 para o Banco do Brasil","persistent-hint":"","dense":"","background-color":"white","counter":"3","maxlength":"3"},model:{value:(_vm.refundForm.bankCode),callback:function ($$v) {_vm.$set(_vm.refundForm, "bankCode", $$v)},expression:"refundForm.bankCode"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Número da Agência *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.onlyNumbers,
                                            ],"hint":"Insira o número da agência bancária","persistent-hint":"","dense":"","background-color":"white","counter":"4","maxlength":"4"},model:{value:(_vm.refundForm.agencia),callback:function ($$v) {_vm.$set(_vm.refundForm, "agencia", $$v)},expression:"refundForm.agencia"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Dígito Verificador da Agência","outlined":"","hint":"Informe o dígito verificador da agência, se o banco utilizar","persistent-hint":"","dense":"","background-color":"white","counter":"1","maxlength":"1"},model:{value:(_vm.refundForm.agenciaDv),callback:function ($$v) {_vm.$set(_vm.refundForm, "agenciaDv", $$v)},expression:"refundForm.agenciaDv"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Número da Conta *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.onlyNumbers,
                                            ],"hint":"Informe o número da conta bancária","persistent-hint":"","dense":"","background-color":"white","counter":"13","maxlength":"13"},model:{value:(_vm.refundForm.conta),callback:function ($$v) {_vm.$set(_vm.refundForm, "conta", $$v)},expression:"refundForm.conta"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Dígito Verificador da Conta *","outlined":"","rules":[_vm.$rules.required],"hint":"Informe o dígito verificador da conta","persistent-hint":"","dense":"","background-color":"white","counter":"2","maxlength":"2"},model:{value:(_vm.refundForm.contaDv),callback:function ($$v) {_vm.$set(_vm.refundForm, "contaDv", $$v)},expression:"refundForm.contaDv"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.type,"item-text":"title","item-value":"value","label":"Tipo da conta bancária *","rules":[_vm.$rules.required],"outlined":"","dense":""},model:{value:(_vm.refundForm.type),callback:function ($$v) {_vm.$set(_vm.refundForm, "type", $$v)},expression:"refundForm.type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[(
                                                _vm.refundForm.documentType ===
                                                    _vm.documentType[0].value
                                            )?_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Nome *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.onlyLetters,
                                            ],"hint":"Nome do favorecido","persistent-hint":"","dense":"","background-color":"white","counter":"30","maxlength":"30"},model:{value:(_vm.refundForm.legalName),callback:function ($$v) {_vm.$set(_vm.refundForm, "legalName", $$v)},expression:"refundForm.legalName"}}):_vm._e(),(
                                                _vm.refundForm.documentType ===
                                                    _vm.documentType[1].value
                                            )?_c('v-text-field',{staticClass:"elevation-0",attrs:{"outline":"","label":"Razão Social *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.onlyLetters,
                                            ],"hint":"Razão social do favorecido","persistent-hint":"","dense":"","background-color":"white","counter":"30","maxlength":"30"},model:{value:(_vm.refundForm.legalName),callback:function ($$v) {_vm.$set(_vm.refundForm, "legalName", $$v)},expression:"refundForm.legalName"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.documentType,"rules":[_vm.$rules.required],"item-text":"title","item-value":"value","label":"Tipo de documento *","hint":_vm.refundForm.documentType ===
                                                'cpf'
                                                    ? 'O CPF do favorecido da operação. Isso identifica a pessoa que vai receber o pagamento.'
                                                    : 'O CNPJ do favorecido da operação. Isso identifica a empresa que vai receber o pagamento.',"persistent-hint":"","outlined":"","dense":""},model:{value:(
                                                _vm.refundForm.documentType
                                            ),callback:function ($$v) {_vm.$set(_vm.refundForm, "documentType", $$v)},expression:"\n                                                refundForm.documentType\n                                            "}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[(
                                                _vm.refundForm.documentType ===
                                                    _vm.documentType[0].value
                                            )?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXX.XXX.XXX-XX'),expression:"'XXX.XXX.XXX-XX'"}],staticClass:"elevation-0",attrs:{"outline":"","label":"CPF *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.validCpf,
                                            ],"hint":"Preencha com o CPF do doador","persistent-hint":"","dense":"","background-color":"white"},model:{value:(
                                                _vm.refundForm.documentNumber
                                            ),callback:function ($$v) {_vm.$set(_vm.refundForm, "documentNumber", $$v)},expression:"\n                                                refundForm.documentNumber\n                                            "}}):_vm._e(),(
                                                _vm.refundForm.documentType ===
                                                    _vm.documentType[1].value
                                            )?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"elevation-0",attrs:{"outline":"","label":"CNPJ *","outlined":"","rules":[
                                                _vm.$rules.required,
                                                _vm.$rules.validCnpj,
                                            ],"hint":"Preencha com o CNPJ do doador","persistent-hint":"","dense":"","background-color":"white"},model:{value:(
                                                _vm.refundForm.documentNumber
                                            ),callback:function ($$v) {_vm.$set(_vm.refundForm, "documentNumber", $$v)},expression:"\n                                                refundForm.documentNumber\n                                            "}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"large":"","color":"success"},on:{"click":_vm.confirm}},[_vm._v("Confirmar")]),_c('v-btn',{attrs:{"large":"","color":"info"},on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }