// Registrar componentes aqui
import Vue from 'vue';
import Draggable from 'vuedraggable';
import Loader from '@/components/shared/Loader';
import Notifier from '@/components/shared/Notifier';
import DeleteConfirmation from '@/components/shared/DeleteConfirmation';
import DisableConfirmation from '@/components/shared/DisableConfirmation';
import ReactivateConfirmation from '@/components/shared/ReactivateConfirmation';
import RefundCreditCardConfirmation from '@/components/shared/RefundCreditCardConfirmation';
import RefundBoletoConfirmation from '@/components/shared/RefundBoletoConfirmation';

Vue.component('Draggable', Draggable);
Vue.component('Loader', Loader);
Vue.component('Notifier', Notifier);
Vue.component('DeleteConfirmation', DeleteConfirmation);
Vue.component('DisableConfirmation', DisableConfirmation);
Vue.component('ReactivateConfirmation', ReactivateConfirmation);
Vue.component('RefundCreditCardConfirmation', RefundCreditCardConfirmation);
Vue.component('RefundBoletoConfirmation', RefundBoletoConfirmation);
