<template>
    <div>
        <v-navigation-drawer v-model="drawer" app dark color="#780836" clipped>
            <menu-items />
        </v-navigation-drawer>
        <v-app-bar app color="#B91256" elevation="3" dark clipped-left>
            <v-app-bar-nav-icon @click="drawer = !drawer" />
            <v-spacer />
            <user-data />
        </v-app-bar>

        <v-container fluid>
            <v-scroll-y-transition mode="out-in">
                <router-view />
            </v-scroll-y-transition>
        </v-container>
    </div>
</template>

<script>
import MenuItems from '@/components/layout/MenuItems';
import UserData from '@/components/layout/UserData';

export default {
    components: {
        MenuItems,
        UserData,
    },
    data() {
        return {
            drawer: false,
        };
    },
    methods: {
        logout() {
            this.$router.push({ name: 'auth.login' });
        },
    },
};
</script>

<style scoped>
#content {
    margin-bottom: 120px;
}
</style>
