<template>
    <v-dialog :value="visible" width="700" persistent>
        <v-card>
            <v-toolbar flat dark dense color="#B91256">
                <v-btn icon dark @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer />
                <v-toolbar-title
                    >Preencha o formulário abaixo para solicitar o
                    estorno</v-toolbar-title
                >
                <v-spacer></v-spacer>
            </v-toolbar>

            <v-form ref="formRefundBoleto">
                <v-container>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" lg="10" md="10" sm="10">
                            <v-card color="#EEF6F6" elevation="0" class="pa-5 ">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                outline
                                                label="Código do Banco *"
                                                v-model="refundForm.bankCode"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.minThreeNumbers,
                                                ]"
                                                hint="O código que identifica o banco. Exemplo: 001 para o Banco do Brasil"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="3"
                                                maxlength="3"
                                            />
                                        </v-col>

                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                outline
                                                label="Número da Agência *"
                                                v-model="refundForm.agencia"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.onlyNumbers,
                                                ]"
                                                hint="Insira o número da agência bancária"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="4"
                                                maxlength="4"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                outline
                                                label="Dígito Verificador da Agência"
                                                v-model="refundForm.agenciaDv"
                                                outlined
                                                hint="Informe o dígito verificador da agência, se o banco utilizar"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="1"
                                                maxlength="1"
                                            />
                                        </v-col>

                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                outline
                                                label="Número da Conta *"
                                                v-model="refundForm.conta"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.onlyNumbers,
                                                ]"
                                                hint="Informe o número da conta bancária"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="13"
                                                maxlength="13"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                outline
                                                label="Dígito Verificador da Conta *"
                                                v-model="refundForm.contaDv"
                                                outlined
                                                :rules="[$rules.required]"
                                                hint="Informe o dígito verificador da conta"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="2"
                                                maxlength="2"
                                            />
                                        </v-col>

                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-select
                                                v-model="refundForm.type"
                                                :items="type"
                                                item-text="title"
                                                item-value="value"
                                                label="Tipo da conta bancária *"
                                                :rules="[$rules.required]"
                                                outlined
                                                dense
                                            ></v-select>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                v-if="
                                                    refundForm.documentType ===
                                                        documentType[0].value
                                                "
                                                outline
                                                label="Nome *"
                                                v-model="refundForm.legalName"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.onlyLetters,
                                                ]"
                                                hint="Nome do favorecido"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="30"
                                                maxlength="30"
                                            />
                                            <v-text-field
                                                v-if="
                                                    refundForm.documentType ===
                                                        documentType[1].value
                                                "
                                                outline
                                                label="Razão Social *"
                                                v-model="refundForm.legalName"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.onlyLetters,
                                                ]"
                                                hint="Razão social do favorecido"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                                counter="30"
                                                maxlength="30"
                                            />
                                        </v-col>

                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-select
                                                v-model="
                                                    refundForm.documentType
                                                "
                                                :items="documentType"
                                                :rules="[$rules.required]"
                                                item-text="title"
                                                item-value="value"
                                                label="Tipo de documento *"
                                                :hint="
                                                    refundForm.documentType ===
                                                    'cpf'
                                                        ? 'O CPF do favorecido da operação. Isso identifica a pessoa que vai receber o pagamento.'
                                                        : 'O CNPJ do favorecido da operação. Isso identifica a empresa que vai receber o pagamento.'
                                                "
                                                persistent-hint
                                                outlined
                                                dense
                                            ></v-select>
                                        </v-col>

                                        <v-col cols="12" lg="6" md="12" sm="12">
                                            <v-text-field
                                                v-if="
                                                    refundForm.documentType ===
                                                        documentType[0].value
                                                "
                                                outline
                                                label="CPF *"
                                                v-model="
                                                    refundForm.documentNumber
                                                "
                                                v-mask="'XXX.XXX.XXX-XX'"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.validCpf,
                                                ]"
                                                hint="Preencha com o CPF do doador"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                            ></v-text-field>
                                            <v-text-field
                                                v-if="
                                                    refundForm.documentType ===
                                                        documentType[1].value
                                                "
                                                outline
                                                label="CNPJ *"
                                                v-model="
                                                    refundForm.documentNumber
                                                "
                                                v-mask="'##.###.###/####-##'"
                                                outlined
                                                :rules="[
                                                    $rules.required,
                                                    $rules.validCnpj,
                                                ]"
                                                hint="Preencha com o CNPJ do doador"
                                                persistent-hint
                                                dense
                                                class="elevation-0"
                                                background-color="white"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            sm="12"
                                            class="d-flex justify-center"
                                        >
                                            <v-btn
                                                large
                                                class="mr-3"
                                                color="success"
                                                @click="confirm"
                                                >Confirmar</v-btn
                                            >
                                            <v-btn
                                                large
                                                color="info"
                                                @click="close"
                                                >Cancelar</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RefundBoletoConfirmation',
    data() {
        return {
            refundForm: {
                bankCode: '',
                agencia: '',
                agenciaDv: '',
                conta: '',
                contaDv: '',
                documentNumber: '',
                documentType: 'cpf',
                legalName: '',
                type: 'conta_corrente',
            },
            type: [
                { title: 'Conta corrente', value: 'conta_corrente' },
                { title: 'Conta poupança', value: 'conta_poupanca' },
                {
                    title: 'Conta corrente conjunta',
                    value: 'conta_corrente_conjunta',
                },
                {
                    title: 'Conta poupança conjunta',
                    value: 'conta_poupanca_conjunta',
                },
            ],
            documentType: [
                { title: 'CPF', value: 'cpf' },
                { title: 'CNPJ', value: 'cnpj' },
            ],
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
        close() {
            this.$emit('cancel');
        },
        confirm() {
            if (!this.$refs.formRefundBoleto.validate()) return;

            this.$emit('confirm', this.refundForm);
        },
    },
};
</script>

<style></style>
