import Vue from 'vue';
import dayjs from 'dayjs';
import mask from '../utils/mask';

Vue.filter('date', date =>
    date === null
        ? 'Indeterminado'
        : dayjs(date.slice(0, 10)).format('DD/MM/YYYY'),
);

Vue.filter('birthDate', date =>
    date === null
        ? 'Usuário Jurídico'
        : dayjs(date.slice(0, 10)).format('DD/MM/YYYY'),
);

Vue.filter(
    'hour',
    date => `${dayjs(date).format('HH')}h${dayjs(date).format('mm')}`,
);

Vue.filter('money', number =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(number / 100),
);

Vue.filter('titlePlan', titlePlan =>
    titlePlan.replace(/- Vaquinha ID \d+/, '').trim(),
);

Vue.filter('trim', (value, size) => {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return `${value.substr(0, size)}...`;
});

Vue.filter('cpf', value => mask.cpfMask(value));

Vue.filter('cnpj', value => mask.cnpjMask(value));
