import store from '../store';

const httpErrorHandler = error => {
    const formatter = new Intl.ListFormat('pt-BR', {
        style: 'long',
        type: 'conjunction',
    });

    if (error.response && error.response.data.message) {
        const message = Array.isArray(error.response.data.message)
            ? formatter.format(error.response.data.message)
            : error.response.data.message;

        store.dispatch('notifier/showError', message);
    } else {
        store.dispatch(
            'notifier/showError',
            'Ops! Algo deu errado. Tente novamente mais tarde! :(',
        );
    }
};

export default httpErrorHandler;
