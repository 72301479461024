const projectCompany = {
    namespaced: true,
    state: {
        company: {},
    },
    mutations: {
        company(state, payload) {
            state.company = payload;
        },
    },
    actions: {
        insertCompany({ commit }, payload) {
            commit('company', payload);
        },
        clearCompany({ commit }) {
            commit('company', {});
        },
    },
    getters: {
        getCompany(state) {
            return state.company;
        },
    },
};

export default projectCompany;
