import { jwtDecode } from 'jwt-decode';
import router from '@/router';

const auth = {
    namespaced: true,
    state: {
        user: {},
        token: window.localStorage.getItem('@donativa/token') || undefined,
    },
    mutations: {
        login(state, payload) {
            state.token = payload;
        },
    },
    actions: {
        login({ commit }, payload) {
            commit('login', payload);
            window.localStorage.setItem('@donativa/token', payload);
            router.push({ name: 'main.home' });
        },
        logout() {
            router.push({ name: 'auth.login' });
        },
        async validateToken({ state, dispatch }) {
            const { token } = state;
            if (!token) return false;

            try {
                const { exp } = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000);

                if (exp < currentTime) {
                    dispatch('logout');
                    return false;
                }
                return true;
            } catch (error) {
                dispatch('logout');
                console.error(error);
            }
        },
    },
    getters: {
        isLogged(state) {
            return !!state.token;
        },
    },
};

export default auth;
