<template>
    <v-app>
        <loader :visible="loading" />
        <notifier type="error" :message="messageError" :show="error" />

        <notifier type="success" :message="messageSuccess" :show="success" />
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'App',
    computed: {
        ...mapState('loader', ['loading']),
        ...mapState('notifier', [
            'error',
            'messageError',
            'success',
            'messageSuccess',
        ]),
    },
    created() {
        if (this.$store.getters['auth/isLogged']) {
            this.$store.dispatch('auth/validateToken');
        }
    },
};
</script>
