/* eslint-disable no-useless-escape */
import Vue from 'vue';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    isValidCEP,
    isValidCNPJ,
    isValidCPF,
    isValidPhone,
} from '@brazilian-utils/brazilian-utils';
import unmaskMoney from '../utils/money';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

Vue.prototype.$rules = {
    required: v => !!String(v) || 'Campo obrigatório',

    email: v =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
        ) || 'E-mail inválido',
    minProjectValue: v => unmaskMoney(v) > 0 || 'Valor mínimo de R$ 1,00',

    minEndDateBase: (initialDate, endDate) => {
        return () =>
            dayjs(endDate).isSameOrAfter(
                dayjs(initialDate).add(1, 'day'),
                'day',
            ) ||
            'Data de encerramento inválida, uma vaquinha deve ter a duração mínima de 1 dia';
    },

    confirmPassword(password1, password2, prop) {
        if (prop) {
            if (password1 === undefined && password2 === '') {
                return true;
            }
            return () => password1 === password2 || 'Senhas diferentes';
        }
        return () => password1 === password2 || 'Senhas diferentes';
    },

    initDateIsBefore: (initialDate, endDate) => {
        return () =>
            dayjs(initialDate).isBefore(dayjs(endDate)) || 'Data inválida';
    },

    validCpf: v => (v ? isValidCPF(v.replace(/[^0-9]/g, '')) : 'CPF inválido'),

    validCnpj: v => (v ? isValidCNPJ(v.replace(/\D/g, '')) : 'CNPJ Inválido'),

    validDate: v =>
        v ? dayjs(v, 'DD/MM/YYYY', true).isValid() : 'Data inválida',

    validCep: v => (v ? isValidCEP(v.replace(/\D/g, '')) : 'CEP inválido'),

    image: v => !!v || 'Adicione uma imagem',

    imageData: value =>
        !value || value.size < 500000 || 'Logo não deve ter mais que 500 KB!',

    imageWebp: value =>
        !value ||
        value.type === 'image/webp' ||
        'Imagem deve ter o formato Webp',

    validPhone: v =>
        isValidPhone(
            String(v).replace(
                /^(?:\+)[0-9]{2}\s?(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/,
                '',
            ),
        ) || 'Telefone inválido',

    minName: v =>
        String(v).length >= 5 || 'Campo deve conter no mínimo 5 caracteres',

    minThreeNumbers: v =>
        (v && (v.match(/\d/g) || []).length >= 3) ||
        'O campo deve conter pelo menos 3 números',

    onlyNumbers: v => /^\d+$/.test(v) || 'O campo deve conter apenas números',

    onlyLetters: v =>
        /^[A-Za-z\s]+$/.test(v) || 'O campo deve conter apenas letras',
};
