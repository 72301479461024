import Vue from 'vue';
import VueRouter from 'vue-router';
import main from './main';
import auth from './auth';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [main, auth],
});

router.beforeEach((to, from, next) => {
    if (to.meta.requireLogin && !store.getters['auth/isLogged']) {
        store.dispatch('auth/logout');
        return;
    }

    next();
});

export default router;
