import PlansService from '../services/plans';

const plans = {
    namespaced: true,
    state: {
        plans: [],
    },
    mutations: {
        plans(state, payload) {
            const newArray = [...state.plans];
            newArray.push(payload);
            state.plans = newArray;
        },
        async deletePlan(state, payload) {
            const updatedArray = [...state.plans];
            const index = updatedArray.findIndex(
                p => p.title === payload.title,
            );

            const projectToDelete = updatedArray[index];

            if (projectToDelete.idPagarme) {
                await PlansService.deleteOne(projectToDelete.id);
            }

            updatedArray.splice(index, 1);
            state.plans = updatedArray;
        },
        clear(state) {
            state.plans = [];
        },
    },
    actions: {
        insertPlans({ commit }, payload) {
            commit('plans', payload);
        },
        deleteOne({ commit }, payload) {
            commit('deletePlan', payload);
        },
        clearPlans({ commit }) {
            commit('clear');
        },
    },
    getters: {
        getPlans(state) {
            return state.plans;
        },
    },
};

export default plans;
