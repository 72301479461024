<template>
    <v-overlay opacity="0.3" :value="visible" :z-index="1000">
        <div class="d-flex d-flex-column justify-center">
            <v-progress-circular
                :width="8"
                size="64"
                indeterminate
                color="#15787C"
            />
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        visible: Boolean,
    },
};
</script>

<style scoped></style>
