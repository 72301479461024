import Vue from 'vue';
import money from 'v-money';
import VueTheMask from 'vue-the-mask';
import { VueMaskFilter } from 'v-mask';
import setupInterceptors from '@/misc/interceptor';
import httpErrorHandler from '@/misc/error';
import toast from '@/misc/toast';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/components/shared';
import '@/misc/rules';
import '@/misc/filters';

Vue.filter('VMask', VueMaskFilter);
Vue.use(VueTheMask);
Vue.use(money, { precision: 2 });
Vue.config.productionTip = false;
Vue.prototype.$toast = toast;
Vue.prototype.$handleHttpError = httpErrorHandler;

new Vue({
    router,
    store,
    created() {
        setupInterceptors(store);
    },
    vuetify,
    render: h => h(App),
}).$mount('#app');
