<template>
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card>
                    <v-card-title
                        class="white--text justify-center"
                        style="background-color: #B91256"
                    >
                        {{ pageName }} - Área restrita
                    </v-card-title>
                    <v-scroll-y-transition mode="out-in">
                        <router-view />
                    </v-scroll-y-transition>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    computed: {
        pageName() {
            return this.$route.matched[this.$route.matched.length - 1].props
                .default.title;
        },
    },
};
</script>

<style></style>
