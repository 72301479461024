import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000,
});

api.interceptors.request.use(config => {
    const token = window.localStorage.getItem('@donativa/token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default api;
